//import logo from './logo.svg'
import './App.css'
import React from 'react'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

import FileUploadPage from './pages/FileUploadPage'

function App() {
  return (
    <div className="App">
      <Container>
        <Card style={{ padding: 25 }}>
          <CardHeader title="ADA-Touch Package Uploader" />
          <FileUploadPage />
        </Card>
      </Container>
    </div>
  )
}

export default App
