import React, { useState } from 'react'
import { Button, Input, TextField, Box } from '@mui/material'
import axios from 'axios'

import bytes from 'bytes'
import LinearProgressWithLabel from '../components/linear-progress-with-label'

function FileUploadPage() {
  const [selectedFile, setSelectedFile] = useState(undefined as any)
  const [isFilePicked, setIsFilePicked] = useState(false)

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)

  const [description, setDescription] = useState('')
  const [progress, setProgress] = useState(0)

  const [submitDisable, setSubmitDisable] = useState(false)

  const [uploadFinished, setUploadFinished] = useState(false)

  function resetInput() {
    setSelectedFile(undefined)
    setIsFilePicked(false)
  }

  function validateEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const changeFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    setSelectedFile(event.target.files[0])
    setIsFilePicked(event.target.files[0] ? true : false)
  }

  const handleSubmission = async () => {
    try {
      if (!isEmailValid || !isFilePicked) {
        return
      }

      setUploadFinished(false)
      setSubmitDisable(true)
      setProgress(0)

      const formData = new FormData()
      formData.append('email', email)
      formData.append('description', description)
      formData.append('uploadedAt', new Date().toISOString())
      formData.append('file', selectedFile)

      await axios.request({
        url: '/upload',
        method: 'POST',
        data: formData,
        onUploadProgress: (p) => {
          const percents = Math.round((p.loaded / p.total) * 100)
          setProgress(percents)
          console.log(percents)
        }
      })

      resetInput()
      setUploadFinished(true)
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitDisable(false)
    }
  }

  return (
    <div>
      <Box component="span" display="flex" justifyContent="space-between" alignItems="center">
        <form>
          <TextField
            sx={{ m: 1 }}
            label="Uploader Email"
            onChange={(e) => {
              setEmail(e.target.value)
              const valid = validateEmail(e.target.value)
              setIsEmailValid(valid)
            }}
            error={!isEmailValid}
            helperText={isEmailValid ? '' : 'Please insert a valid email'}
            fullWidth
            required
          />
          <TextField
            sx={{ m: 1 }}
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            fullWidth
            required
          />
          <Input
            sx={{ m: 1 }}
            type="file"
            inputProps={{ accept: '.zip' }}
            name="File"
            onChange={changeFileHandler}
            fullWidth
            required
          />
          {isFilePicked ? <p>Size in bytes: {bytes(selectedFile.size)}</p> : <p></p>}
          <Button variant="contained" onClick={handleSubmission} disabled={submitDisable}>
            Submit
          </Button>
        </form>
      </Box>
      <LinearProgressWithLabel sx={{ m: 1 }} value={progress} />
      {uploadFinished ? <p> Upload successfully finished</p> : <p></p>}
    </div>
  )
}

export default FileUploadPage
